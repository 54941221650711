import "./Home.css";
import emailjs from "@emailjs/browser";
import { SiWhatsapp } from "react-icons/si";
import sec1_img from "../../photos/home_sec1.png";
import sec1_img_arabic from "../../photos/home_sec1_arabic.png";
import sec4_img1 from "../../photos/home_sec4_1.png";
import sec4_img2 from "../../photos/home_sec4_2.png";
import sec4_img3 from "../../photos/home_sec4_3.png";
import products from "../../Products_array";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext, useRef } from "react";
import { UserContext } from "../../UserContext";
import sec2_img from "../../photos/home_sec2.png";
import NavbarHome from "../../component/Navebar/NavbarHome";
import { Link } from "react-router-dom";
import Footer from "../../component/footer/Footer";
import { useTranslation } from "react-i18next";
export default function Home() {
  const [user] = useContext(UserContext);
  const generateWhatsAppLink = () => {
    // Replace "1234567890" with your WhatsApp number
    const phoneNumber = "01010749386";
    // Replace "Hello!" with your pre-filled message
    const message = "Welcom to EgyptGSP";
    const countryCode = "+2";
    return `https://wa.me/${countryCode}${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
  };
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_m09bqq6", "template_x88qs7h", form.current, {
        publicKey: "3ZhM568SE03vlE3-T",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };
  const { t } = useTranslation();

  return (
    <>
      <section className="floating_whatssapp">
        <Link
          to={generateWhatsAppLink()}
          target="_blank"
          rel="noopener noreferrer">
          <SiWhatsapp className=" whatssapp_icon" />
        </Link>
      </section>
      <section className=" sec1_bg">
        <NavbarHome />
        <div className="container-fluid h-75 ">
          <div className=" sec1_home text-white  ">
            <div className="rectangel_home">
              {user === "ar" ? (
                <img src={sec1_img_arabic} alt="" className=" w-100 h-100 " />
              ) : (
                <img src={sec1_img} alt="" className=" w-100 h-100 " />
              )}
            </div>
            <div className=" sec_1_home_text">
              <h3>
                {t("Discover smart innovations that make")} <br />
                {t("your life easier and more fun.")}
              </h3>
              <p className="mt-4">
                {t("with advanced technologies from our company.")}
              </p>
            </div>
          </div>
          </div>
      </section>
      <section className="m-78 pt-5 ">
        <div className=" container-fluid row w-100 ">
          <div className="col-xxl-7 col-xl-7  col-lg-7 col-md-11 mt-5 ms-3 text_center_media home_sec2">
            <h3>{t("About Us")}</h3>

            <h2 className="sec2_text_color mt-5">
              {t("Explore a new world of intelligence and")} <br />
              {t("comfort with")} <span> {t("GSP Company.")}</span>
            </h2>
            <p className="sec4_fontsize mt-2">
              {t("We offer smart and innovative solutions that transform your")}
              <br />
              {t("home into a connected and advanced environment.")}
              <br />
              {t("Discover now and experience smart homes in a new way.")}
            </p>
            <div className=" mt-5">
              <Link to={"/AboutUs"}>
                <button type="submit" className="sec5_btn  py-1 mb-5">
                  {t("More About Us →")}
                </button>
              </Link>
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4  col-lg-4 col-md-6 col-6 mt-5 display_hide ">
            <img src={sec2_img} alt="" className="w-100 " />
          </div>
        </div>
      </section>
      <section className="m-78 pt-5 mb-5">
        <div className=" container-fluid row w-100 ">
          <div className=" mt-5 ms-3 text_center_media home_sec2">
            <h3>{t("Why Us ?")}</h3>

            <h2 className="sec2_text_color mt-5">
              {t("The Benefits of Partnering")} <br />
              {t("with")} <span> {t("GSP Company.")}</span>
            </h2>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mt-5 sec4_text_color">
            <h1 className="">01.</h1>
            <div className="sec3_underline mb-2 mt-2"></div>
            <h4>{t("Smart Home Solutions")}</h4>
            <p>
              {t("We offer you the best solution for your life")} <br />
              {t("Providing the best devices with the highest quality")}
              <br /> {t("Install it, connect it to your home, and prepare the house for that")}
            </p>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mt-5 sec4_text_color">
            <h1 className="">02.</h1>
            <div className="sec3_underline mb-2 mt-2"></div>
            <h4>{t("Smart Home Automation")}</h4>
            <p>
              {t("Our goal is to reduce human effort")} <br />{" "}
              {t("Adding auxiliary tools and equipment to this")}
              <br /> {t("This makes the devices connected to each other and easy to use")}{" "}
            </p>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mt-5 sec4_text_color">
            <h1 className="">03.</h1>
            <div className="sec3_underline mb-2 mt-2"></div>
            <h4>{t("Easy Control and Accessibility")}</h4>
            <p>
              {t("Here lies the strength in performance")} <br />
              {t("The presence of applications that facilitate access to devices")}
              <br /> 
              {t("By making all devices connected to each other")}
            </p>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mt-5 sec4_text_color">
            <h1 className="">04.</h1>
            <div className="sec3_underline mb-2 mt-2"></div>
            <h4>{t("Enhanced Home Security")}</h4>
            <p>
              {t("Not only a group of devices that make your home smart")} <br />
              {t("But it also makes your home safer")}
              <br />
               {t("Giving you the opportunity to easily monitor all parts of your home")}
            </p>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mt-5 sec4_text_color">
            <h1 className="">05.</h1>
            <div className="sec3_underline mb-2 mt-2"></div>
            <h4>{t("Seamless Device Integration")}</h4>
            <p>
              {t("Here lies our advantage")} <br />
              {t("They are not present and smart devices, but they are devices")}
              <br /> {t("They are connected to each other and can all be controlled")}
            </p>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mt-5 sec4_text_color">
            <h1 className="">06.</h1>
            <div className="sec3_underline mb-2 mt-2"></div>
            <h4>{t("Support and Updates")}</h4>
            <p>
              {t("We provide you with the possibility of continuous maintenance")} <br />{" "}
              {t("And also continuous updating with the latest versions")}
              <br /> {t("Software for devices and allows adding more devices")}{" "}
            </p>
          </div>
          <div></div>
        </div>
      </section>
      <section className="sec4_home h-100 pb-4 text_center_media ">
        <div className="m-78 pt-5 mb-5">
          <div className=" container-fluid row w-100">
            <div className=" col-12 mt-5 ms-3 text_center_media home_sec4 d-flex justify-content-between">
              <div>
                <h3>{t("Our Services")}</h3>

                <h2 className="sec2_text_color mt-5">
                  {t("Smart Home Services for")} <br />
                  {t("Modern Living")}
                </h2>
              </div>
              <div className="text-center mt-5 ">
                <Link to={"/Services"}>
                  <button
                    type="submit"
                    className="sec4_btn  py-1 mb-5 display_hide1_sec4">
                    {t("See All Services →")}
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mt-5 mb-5 home_sec4">
              <img src={sec4_img1} alt="" className="w-100 " />
              <h4 className="mt-4"> {t("Smart Home Consultation")}</h4>
              <p>
                {t("We provide you with solutions and suggestions")} <br />
                {t("We also answer all your questions")} <br />
                {t("In addition to free consultation")}
              </p>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mt-5 home_sec4">
              <img src={sec4_img2} alt="" className="w-100 " />
              <h4 className="mt-4"> {t("Smart Home Installation")}</h4>
              <p>
                {t("We provide you with tools and equipment")} <br />
                {t("We prepare the house to install the appliances")} <br />
                {t("And in the end you get a completely smart home")}
              </p>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mt-5 home_sec4">
              <img src={sec4_img3} alt="" className="w-100 " />
              <h4 className="mt-4"> {t("Home Security Integration")}</h4>
              <p>
                {t("We take care to prepare the house to protect it")} <br />
                {t("With the latest tools and methods")} <br />
                {t("Make sure it is impenetrable")}
              </p>
            </div>
          </div>
          <div className="text-center mt-5 ">
            <Link to={"/Services"}>
              <button
                type="submit"
                className="sec4_btn  py-1 mb-5 display_hide2_sec4">
                {t("See All Services →")}
              </button>
            </Link>
          </div>
        </div>
      </section>
      <section className=" m-78   ">
        <div className=" container-fluid pt-5 mb-5 ">
          <div className="row justify-content-center">
            <div className=" col-12 mt-5 text_center_media home_sec4 text-center">
              <h3>{t("Our Products")}</h3>

              <h2 className="text-black mt-5">
                {t("Discover Our Range of Innovative")} <br />
                {t("Smart Home Products")}
              </h2>
            </div>
            {products.slice(0, 4).map((item, index) => (
              <div
                key={index}
                className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 mt-5  home_sec5">
                <img src={item.imageUrl} alt="" className="w-100" />
                <div className="d-flex justify-content-between ">
                  <h5 className="mt-4 ms-2 text-black">{item.name}</h5>
                  <Link
                    to={`/Details/${item.id}`}
                    onClick={()=>{window.scrollTo(0, 0)}}
                    className="text-decoration-none">
                   
                    <h5 className="mt-4 me-2 text-black">
                     
                      {t("See Details")}
                    </h5>
                  </Link>
                </div>
              </div>
            ))}
      
            <div className="text-center mt-5">
              <Link to={"/products"}>
                <button type="submit" className="sec5_btn  py-1 mb-5">
                  {t("See All Products →")}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid  sec6_bg  ">
        <div className="m-78 ">
          <div className="row  h-100  align-items-center  ">
            <div className=" col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12  text_center_media home_sec6 ">
              <h3>{t("Contact Us")}</h3>

              <h2 className=" mt-5">
                {t("Connecting You to")} <br />
                <span>{t("Smarter")}</span> {t("Living Solution")}
              </h2>
              <p>
                {t("Feel free to contact us now for more information")} <br />
                {t("or to get a free consultation!")}
              </p>
            </div>

            <form
              className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12  "
              ref={form}
              onSubmit={sendEmail}>
              <div className="home_sec6-form  pt-4 ">
                <div className="d-flex justify-content-center ">
                  <input
                    type="text"
                    name="to_name"
                    placeholder={t("Name")}
                    className="sec6_input ps-3   "
                  />
                </div>
                <div className="d-flex justify-content-center ">
                  <input
                    type="text"
                    name="to_Phone"
                    placeholder={t("Phone Number")}
                    className="sec6_input mt-4 ps-3  "
                  />
                </div>
                <div className="d-flex justify-content-center ">
                  <input
                    type="text"
                    name="to_Email"
                    placeholder={t("Email")}
                    className="sec6_input mt-4 ps-3  "
                  />
                </div>
                <div className="d-flex justify-content-center ">
                  <input
                    type="text"
                    name="message"
                    placeholder={t("Message")}
                    className="sec6_input_message mt-4 ps-3 "
                  />
                </div>
                <div className="text-center mt-5">
                  <button type="submit" className="sec6_btn  py-1 mb-5">
                    {t("Send ➢")} 
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <ToastContainer />
      <section>
        <Footer />
      </section>
    </>
  );
}
