import NavBar from "../../component/Navebar/Navbar";
import "./AboutUs.css";
import Aboutus_se2 from "../../photos/aboutus-sec2.png";
import Aboutus_se3 from "../../photos/aboutus-sec3.png";
import Footer from "../../component/footer/Footer";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../UserContext";
import { useContext } from "react";
export default function AboutUs() {
  const [user] = useContext(UserContext);
  const { t } = useTranslation();
  return (
    <>
      <section className={user === "ar"
                            ? "Aboutus_bg_ar  vh-100"
                            : "Aboutus_bg vh-100" }  >
      <NavBar />
      <div className="container-fluid h-100">
          <div className="row text-white sec1_media h-50 align-items-center ">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12  ">
              <div className="  text-center   Aboutus_text_sec1 ">
                <h1 className=" ">{t("About Us")}</h1>
                <p>
                  {t(
                    "Discover a new level of innovation and sophistication with"
                  )}
                  <br />
                  {t("our tailored Smart Home solutions.")}
                </p>
              </div>
            </div>
          </div>
          </div>
      </section>
      <section className=" m-78 mt-5">
        <div className="row pt-5 container-fluid sec2_media " >
          <div className="col-xxl-7 col-xl-7 col-lg-12 col-12 ">
            <div className="about_text_sec2 home_sec2 aboutus_sec2">
              <h3 className="">{t("About Us")}</h3>
              <div className="col-xxl-4 col-xl-4 col-lg-12 col-12 d-flex justify-content-center ">
                <img
                  src={Aboutus_se2}
                  alt=""
                  className=" pt-5 aboutus_hide_img w-100"
                />
              </div>
              <h2 className="mt-5">{t("Welcome to GSP Company!")}</h2>
              <p className="">
                {t(
                  "We specialize in transforming homes into smart living spaces with"
                )}
                <br />
                {t(
                  "cutting-edge technology. Our comprehensive services include installing"
                )}
                <br />
                {t(
                  "and configuring smart devices for lighting, temperature control, security,"
                )}
                <br />
                {t(
                  "surveillance, and home entertainment. With our expertise, we ensure a"
                )}
                <br />
                {t(
                  "seamless and enjoyable smart home experience for every customer."
                )}
                <br />
                {t("Discover the possibilities for your home today!")}
              </p>
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-12 col-12 d-flex justify-content-center">
            <img
              src={Aboutus_se2}
              alt=""
              className=" pt-5 aboutus_show_img w-100"
            />
          </div>
        </div>
      </section>
      <section className=" m-78 mt-5">
        <div className="row pt-5 container-fluid">
          <div className="col-xxl-4 col-xl-4 col-lg-12 col-12 d-flex justify-content-center ms-3">
            <img
              src={Aboutus_se3}
              alt=""
              className=" pt-5 aboutus_show_img"
            />
          </div>
          <div className="col-xxl-7 col-xl-7 col-lg-12 col-12 ">
            <div className="about_text_sec2 home_sec2">
              <h3 className="">{t("Our vision")}</h3>
              <div className="col-xxl-4 col-xl-4 col-lg-12 col-12 d-flex justify-content-center ">
                <img
                  src={Aboutus_se3}
                  alt=""
                  className=" pt-5 aboutus_hide_img w-100"
                />
              </div>
             
              <p className="mt-5">
                {t(
                  "Our vision at the company revolves around transforming every home into"
                )}
                <br />
                {t(
                  "advanced smart home, where technology becomes an integral part of"
                )}
                <br />
                {t(
                  "people's lives. We aim to provide an integrated and enhanced experience"
                )}
                <br />
                {t(
                  "that promotes comfort, enhances security, and fosters connectivity with"
                )}
                <br />
                {t(
                  "the home, empowering individuals to enjoy a harmonious and seamless"
                )}
                <br />
                {t("life within their residences.")}
              </p>
            </div>
          </div>
        
        </div>
      </section>
      <section className=" m-78 mt-5 pb-5">
        <div className="row pt-5 container-fluid">
          <div className="col-xxl-7 col-xl-7 col-lg-12 col-12 ">
            <div className="about_text_sec2 home_sec2 aboutus_sec4">
              <h3 className="">{t("Our Goals")}</h3>
              <li className="mt-5 ">{t("Delivering the Highest Quality.")}</li>
              <li className="mt-2">{t("Business Expansion and Growth.")}</li>
              <li className="mt-2">{t("Customer Satisfaction.")}</li>
              <li className="mt-2">{t("Competitive Excellence.")}</li>
              <li className="mt-2">
                {t("Sustainability and Social Responsibility.")}
              </li>
              <li className="mt-2">
                {t("Continuous Innovation and Development.")}
              </li>
              <li className="mt-2">
                {t(
                  "Building Strong Relationships with Partners and Customers."
                )}
              </li>
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-12 col-12 ">
            <img
              src={Aboutus_se2}
              alt=""
              className=" pt-5 aboutus_show_img w-100"
            />
          </div>
        </div>
      </section>
      <section className="mt-5">
        <Footer />
      </section>
    </>
  );
}
