import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "@fortawesome/fontawesome-free/css/all.min.css";
import App from "./App";
import i18n from "./i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root"),
  (document.body.dir = i18n.dir())
);
root.render(<App />);
