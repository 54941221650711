import React, { useState } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineLanguage } from "react-icons/md";
import { Link } from "react-router-dom";
import { UserContext } from "../../UserContext";

function LanguageSelector() {
  const { i18n } = useTranslation();

  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng);
  // };
  const [isArabic, setIsArabic] = useState(i18n.language === "ar");
  const [user, setuser] = useContext(UserContext);
  const toggleLanguage = () => {
    const newLanguage = isArabic ? "en" : "ar";
    i18n.changeLanguage(newLanguage);
    setIsArabic(!isArabic);
    setuser(newLanguage);
  };
  return (
    <>
      <Link  className="languag_btn " onClick={toggleLanguage}>
        <MdOutlineLanguage size={24}  />
      </Link>
      {/* <button >
        <MdOutlineLanguage />
      </button>
      <button >
        <MdOutlineLanguage />
      </button> */}
    </>
  );
}

export default LanguageSelector;
