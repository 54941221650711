import NavBar from "../../component/Navebar/Navbar";
import "./ContactUs.css";
import { BsFillTelephoneFill } from "react-icons/bs";
import { IoMailSharp } from "react-icons/io5";
import { IoLocationSharp } from "react-icons/io5";
import Footer from "../../component/footer/Footer";
import { useTranslation } from "react-i18next";
import { UseAxiosPostContactUs } from "../../component/axios/PostApi/PostApi";
import { useContext, useState } from "react";
import { ToastContainer } from "react-toastify";
import { UserContext } from "../../UserContext";
export default function ContactUs() {
  const [user] = useContext(UserContext);
  const { t } = useTranslation();
  const [ContactUs, setContactUs] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  function getContactinfo(e) {
    let contactinfo = { ...ContactUs };
    contactinfo[e.target.name] = e.target.value;
    setContactUs(contactinfo);
  }
  const { HandelPostApi } = UseAxiosPostContactUs(ContactUs);
  const itemsubmit = (e) => {
    e.preventDefault();
    HandelPostApi();
  };
  return (
    <>

      <section className={user === "ar"
                            ? "ContactUs_bg_ar vh-100"
                            : "ContactUs_bg vh-100"}>
      <NavBar />
      <div className="container-fluid  h-100">
        <div className="row text-white sec1_media h-50 align-items-center">
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-12   ">
            <div className=" text-center Aboutus_text_sec1">
              <h1 className=" ">{t("Contact Us")}</h1>
              <h6>{t("Reach out to us and let's start a conversation!")}</h6>
            </div>
          </div>
        </div>
        </div>
      </section>
      <section className="mt-5 m-78">
        <div className="row pt-5 container-fluid ">
          <div className="col-xxl-6 col-xl-6 col-lg-12 col-12 mt-5 contactus_text ContactUs_text_center">
            <div>
              <h4>{t("Contact Us")}</h4>
              <h2 className="mt-5">{t("Get In Touch")}</h2>
              <p className="mt-4">
                {t("Stay connected with us without hesitation,")}
                <br />
                {t("we're here for you around the clock!")}
              </p>
            </div>
            <div className=" contactus_media_space">
              <div  className="d-flex mt-4">
                <BsFillTelephoneFill className="icons_color" />
                <div >
                  <h5 className="ms-3">{t("Phone")}</h5>
                  <h5 className="ms-3">01010749386</h5>
                </div>
              </div>
              <div  className="d-flex mt-4">
                <IoMailSharp className="icons_color" />
                <div >
                  <h5 className="ms-3">{t("Email")}</h5>
                  <h5 className="ms-3">gspegypt@gmail.com</h5>
                </div>
              </div>
            </div>
            <div  className="d-flex mt-4">
                <IoLocationSharp className="icons_color" />
                <div>
                  <h5 className="ms-3">{t("Address")}</h5>
                  <h5 className="ms-3">01010749386</h5>
                </div>
              </div>
          </div>
          <form
            className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 mt-5  text-center  "
            onSubmit={itemsubmit}>
            <div className=" w-100 ">
              <input
                type="text"
                onChange={getContactinfo}
                name="name"
                placeholder={t("Name")}
                className="sec2-contact_input ps-3   m-auto"
              />
              <input
                type="text"
                onChange={getContactinfo}
                name="phone"
                placeholder={t("Phone Number")}
                className="sec2-contact_input mt-4 ps-3   m-auto"
              />
              <input
                type="text"
                onChange={getContactinfo}
                name="email"
                placeholder={t("Email")}
                className="sec2-contact_input mt-4 ps-3   m-auto"
              />
              <input
                type="text"
                onChange={getContactinfo}
                name="message"
                placeholder={t("Message")}
                className="sec2-contact_input_mass mt-4 ps-3   m-auto"
              />
            </div>
            <div className=" mt-5 ">
              <button type="submit" className="contactUs_bn  py-1 mb-5 ">
                {t("Send ➢")}
              </button>
            </div>
          </form>
        </div>
      </section>
      <ToastContainer />
      <section>
        <Footer />
      </section>
    </>
  );
}
