import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

function LanguageWrapper({ children }) {
  const { i18n } = useTranslation();

  useEffect(() => {
    const currentLanguage = i18n.language;
    const isRtl = ["ar", "he"].includes(currentLanguage); // Add any other RTL language codes if needed

    if (isRtl) {
      document.body.dir = "rtl";
    } else {
      document.body.dir = "ltr";
    }

    // Change document language attribute based on the selected language
    document.documentElement.lang = currentLanguage;
  }, [i18n.language]);

  return <>{children}</>;
}

export default LanguageWrapper;
