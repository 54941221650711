import NavBar from "../../component/Navebar/Navbar";
import "./Products.css";
import products from "../../Products_array"
import Footer from "../../component/footer/Footer";
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../UserContext";
export default function Products() {
  const [user] = useContext(UserContext);
  console.log(products)
  const { t } = useTranslation();
  const [Data, setData] = useState(products.slice(0, 4)); // Initially show 3 items
  const [seeMoreCount, setSeeMoreCount] = useState(0);
  const maxSeeMoreCount = 1; // Maximum number of times "See More" can be clicked
  const itemsPerSeeMoreClick = 4;

  const toggleShowMore = () => {
    const newCount = seeMoreCount + 1;
    if (newCount <= maxSeeMoreCount) {
      setData(products.slice(0, (newCount + 1) * itemsPerSeeMoreClick));
      setSeeMoreCount(newCount);
    }
  };

  const toggleShowLess = () => {
    setData(products.slice(0, itemsPerSeeMoreClick));
    setSeeMoreCount(0);
  };
  return (
    <>
    
      <section className={user === "ar"
                            ? "products_bg_ar vh-100"
                            : "products_bg vh-100"}   >
      <NavBar />
      <div className="container-fluid h-100">
        <div className="row text-white sec1_media  h-50 align-items-center">
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-12  ">
            <div className=" text-center Aboutus_text_sec1">
              <h1 className=" ">{t("Our Products")}</h1>
              <p>
                {t("Discover our comprehensive range of products used at")}
                <br />
                {t("GSP Company, and get ready to experience transforming")}
                <br />
                {t("your home into a smart and connected environment.")}
              </p>
            </div>
          </div>
        </div>
        </div>
      </section>
      <section className=" m-78 mt-5">
        <div className="row pt-5">
          <div className="col-12 text-center products_sec2 mt-5">
            <h4>{t("Our Products")}</h4>
            <h2 className="mt-5">{t("Smart home solutions in one place.")}</h2>
          </div>
        </div>
      </section>
      <section className=" m-78 mt-5 ">
        <div className="row pt-5">
        {Data.map((item, index) => (
          <div key={index} className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 mt-4  home_sec5">
            <img src={item.imageUrl} alt="" className="w-100" />
            <div className="d-flex justify-content-between ">
              <h5 className="mt-4 ms-2 text-black">{item.name}</h5>
              <Link to={`/Details/${item.id}`}   onClick={()=>{window.scrollTo(0, 0)}} className="text-decoration-none"><h5 className="mt-4 me-2 text-black"> {t("See Details")}</h5></Link> 
            </div>
          </div>
        ))}
       
          {/* <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 mt-5  home_sec5">
            <img src={sec5_img2} alt="" className="w-100" />
            <div className="d-flex justify-content-between ">
              <h5 className="mt-4 ms-2 text-black"> {t("Smart Lock")}</h5>
              <h5 className="mt-4 me-2 text-black"> {t("See Details")}</h5>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 mt-5  home_sec5">
            <img src={sec5_img3} alt="" className="w-100" />
            <div className="d-flex justify-content-between ">
              <h5 className="mt-4 ms-2 text-black"> {t("Smart Lamp")}</h5>
              <h5 className="mt-4 me-2 text-black"> {t("See Details")}</h5>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 mt-5  home_sec5">
            <img src={sec5_img4} alt="" className="w-100" />
            <div className="d-flex justify-content-between ">
              <h5 className="mt-4 ms-2 text-black"> {t("System Package")}</h5>
              <h5 className="mt-4 me-2 text-black"> {t("See Details")}</h5>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 mt-5  home_sec5">
            <img src={sec5_img5} alt="" className="w-100" />
            <div className="d-flex justify-content-between ">
              <h5 className="mt-4 ms-2 text-black"> {t("Smart home panel")}</h5>
              <h5 className="mt-4 me-2 text-black"> {t("See Details")}</h5>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 mt-5  home_sec5">
            <img src={sec5_img6} alt="" className="w-100" />
            <div className="d-flex justify-content-between ">
              <h5 className="mt-4 ms-2 text-black"> {t("home automation app")}</h5>
              <h5 className="mt-4 me-2 text-black"> {t("See Details")}</h5>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 mt-5  home_sec5">
            <img src={sec5_img7} alt="" className="w-100" />
            <div className="d-flex justify-content-between ">
              <h5 className="mt-4 ms-2 text-black"> {t("Smart Speaker")}</h5>
              <h5 className="mt-4 me-2 text-black"> {t("See Details")}</h5>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 mt-5  home_sec5">
            <img src={sec5_img8} alt="" className="w-100" />
            <div className="d-flex justify-content-between ">
              <h5 className="mt-4 ms-2 text-black"> {t("Smart Mirror")}</h5>
              <h5 className="mt-4 me-2 text-black"> {t("See Details")}</h5>
            </div>
          </div> */}
        </div>
        <div className="text-center mt-4">
        {seeMoreCount < maxSeeMoreCount ? (
        <button className="seeMore_btn py-2" onClick={toggleShowMore}>
          {t("See More →")}
        </button>
      ) : (
        <button className="seeMore_btn py-2" onClick={toggleShowLess}>
          {t("Show Less →")}
        </button>
      )}
      </div>
      </section>

      <section className="mt-5">
        <Footer />
      </section>
    </>
  );
}
