import NavBar from "../../component/Navebar/Navbar";
import "./Services.css";
import sec4_img1 from "../../photos/home_sec4_1.png";
import sec4_img2 from "../../photos/home_sec4_2.png";
import sec4_img3 from "../../photos/home_sec4_3.png";
import sec4_img4 from "../../photos/home_sec4_4.png";
import sec4_img5 from "../../photos/home_sec4_5.png";
import sec4_img6 from "../../photos/home_sec4_6.png";
import Footer from "../../component/footer/Footer";
import { UserContext } from "../../UserContext";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
export default function Services() {
  const [user] = useContext(UserContext);
  const { t } = useTranslation();
  return (
    <>
    
      <section className={user === "ar"
                            ? "services_bg_ar vh-100"
                            : "services_bg vh-100"}>
      <NavBar />
      <div className="container-fluid h-100">
        <div className="row text-white sec1_media h-50 align-items-center">
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-12  ">
            <div className=" text-center Aboutus_text_sec1">
              <h1 className=" ">{t("Services")}</h1>
              <p>{t("Welcome to our gateway of exceptional services.")}</p>
            </div>
          </div>
        </div>
        </div>
      </section>
      <section className=" m-78 mt-5">
        <div className="row pt-5">
          <div className="col-12  services_sec2 mt-5">
            <h4>{t("Our Services")}</h4>
            <h2 className="mt-5">
              {t("Transforming houses into")} <span>{t("smart homes,")}</span>{" "}
              <br />
              {t("innovative services that catch the eye.")}
            </h2>
          </div>
        </div>
      </section>
      <section className=" m-78  ">
        <div className="row pt-5">
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mt-5 mb-5 services_sec3">
            <img src={sec4_img1} alt="" className="w-100 " />
            <h4 className="mt-4"> {t("Smart Home Consultation")}</h4>
            <p>
                {t("We provide you with solutions and suggestions")} <br />
                {t("We also answer all your questions")} <br />
                {t("In addition to free consultation")}
              </p>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mt-5 mb-5 services_sec3">
            <img src={sec4_img2} alt="" className="w-100" />
            <h4 className="mt-4"> {t("Smart Home Installation")}</h4>
            <p>
                {t("We provide you with tools and equipment")} <br />
                {t("We prepare the house to install the appliances")} <br />
                {t("And in the end you get a completely smart home")}
              </p>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mt-5 mb-5 services_sec3">
            <img src={sec4_img4} alt="" className="w-100" />
            <h4 className="mt-4"> {t("Smart Home Security")}</h4>
            <p>
                {t("We take care to prepare the house to protect it")} <br />
                {t("With the latest tools and methods")} <br />
                {t("Make sure it is impenetrable")}
              </p>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mt-5 mb-5 services_sec3">
            <img src={sec4_img3} alt="" className="w-100" />
            <h4 className="mt-4"> {t("Home Security Integration")}</h4>
            <p>
                {t("We provide you with continuous communication at home")} <br />
                {t("Wherever you are, you can access your home devices")} <br />
                {t("The security services are also connected to each other")}
              </p>
          </div>
          
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mt-5 mb-5 services_sec3">
            <img src={sec4_img5} alt="" className="w-100" />
            <h4 className="mt-4"> {t("Smart Home Aplication")}</h4>
            <p>
              {t("We offer you an application for most devices")} <br />
              {t("It gives you comfort and the ability to control from anywhere")} <br />
              {t("Which adds privacy to you in controlling it from your phone only")}
            </p>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mt-5 mb-5 services_sec3">
            <img src={sec4_img6} alt="" className="w-100" />
            <h4 className="mt-4"> {t("Smart Home dashboard")}</h4>
            <p>
              {t("The basic panel that gives you the possibility")} <br />
              {t("To fully control home appliances")}
            </p>
          </div>
        </div>
      </section>

      <section className="mt-5">
        <Footer />
      </section>
    </>
  );
}
