import Security_Cam from "../src/photos/sec-5_home1.png";
import Smart_Lock from "../src/photos/sec-5_home2.png";
import Smart_Lamp from "../src/photos/sec-5_home3.png";
import System_Package from "../src/photos/sec-5_home4.png";
import Smart_home_panel from "../src/photos/sec-5_home5.png";
import home_automation_app from "../src/photos/sec-5_home6.png";
import Smart_Speaker from "../src/photos/sec-5_home7.png";
import Smart_Mirror from "../src/photos/sec-5_home8.png";
const  products_array = 
[
  {
    "id": 0,
    "name": "Security Cam",
    "price": 10.99,
    "description": "This is the description of product 1.",
    "imageUrl": Security_Cam,
    "images": [Security_Cam,Security_Cam,Security_Cam,Security_Cam]
  },
    {
      "id": 1,
      "name": "Smart Lock",
      "price": 10.99,
      "description": "This is the description of product 1.",
      "imageUrl": Smart_Lock,
      "images": []
    },
    {
      "id": 2,
      "name": "Smart Lamp",
      "price": 19.99,
      "description": "This is the description of product 2.",
      "imageUrl": Smart_Lamp,
      "images": []
    },
    {
      "id": 3,
      "name": "System Package",
      "price": 29.99,
      "description": "This is the description of product 3.",
      "imageUrl": System_Package,
      "images": []
    },
    {
      "id": 4,
      "name": "Smart home panel",
      "price": 29.99,
      "description": "This is the description of product 3.",
      "imageUrl": Smart_home_panel,
      "images": []
    },
    {
      "id": 5,
      "name": "home automation app",
      "price": 29.99,
      "description": "This is the description of product 3.",
      "imageUrl": home_automation_app,
      "images": []
    },
    {
      "id": 6,
      "name": "Smart Speaker",
      "price": 29.99,
      "description": "This is the description of product 3.",
      "imageUrl": Smart_Speaker,
      "images": []
    },
    {
      "id": 7,
      "name": "Smart Mirror",
      "price": 29.99,
      "description": "This is the description of product 3.",
      "imageUrl": Smart_Mirror,
      "images": []
    },

   
  ]
  export default products_array