import "./Details.css";
import { ToastContainer } from "react-toastify";
import Footer from "../../component/footer/Footer";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { UseAxiosPostitemQuestion } from "../../component/axios/PostApi/PostApi";
import NavbarHome from "../../component/Navebar/NavbarHome";
import products from "../../Products_array";
export default function Details() {
  const { ID } = useParams();
  const productId = parseInt(ID);
  const Data_imgs = products.find((array) => array.id === productId);
  console.log(Data_imgs);
  const [Tour, setTour] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
    itemName: Data_imgs.name,
  });

  function getTourinfo(e) {
    let Tourinfo = { ...Tour };
    Tourinfo[e.target.name] = e.target.value;
    setTour(Tourinfo);
  }
  const { HandelPostApi } = UseAxiosPostitemQuestion(Tour);
  const itemsubmit = (e) => {
    e.preventDefault();
    HandelPostApi();
  };
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const cols = isSmallScreen ? 2 : 4;
  return (
    <>
      <section className="details_nav">
        <NavbarHome />
      </section>
      <section className="m-78 mt-5">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <h3>{Data_imgs.name}</h3>
            <h3>{Data_imgs.price}</h3>
          </div>
          <div className="detail_underline"></div>
        </div>
      </section>
      <section className="m-78 pt-4 pb-5">
        <div className="container-fluid ">
          <div className="row details_media_center">
            <div className="col-xxl-7 col-xl-7 col-lg-7 col-12 mb-5 ">
              <div className="mt-2">
                <ImageList
                  // sx={{ width: 700, height: 200 }}
                  cols={cols}
                  rowHeight={150}>
                  {Data_imgs.images.map((item, index) => (
                    <ImageListItem key={index}>
                      <img src={item} alt="" loading="lazy" />
                    </ImageListItem>
                  ))}
                </ImageList>
              </div>
              <div>
                <h2>{t("Overview")}</h2>
                <h6>{Data_imgs.description}</h6>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4  col-lg-4 col-12">
              <h4 className="mt-2 ps-3 detail_form_text">
                {t("Get questions about")} <span > {t("the product")} </span>
              </h4>
              <h6 className="ps-3">{t("Send us a message and enquire now!")}</h6>
              <div className="mt-4 Details_requist mx-auto">
                <form onSubmit={itemsubmit} action="">
                  <div className="text-center">
                    <input
                      type="text"
                      onChange={getTourinfo}
                      name="name"
                      placeholder={t("Name")}
                      className=" ps-3 mt-4 Details_requist_input"
                    />
                    <input
                      type="text"
                      onChange={getTourinfo}
                      name="phone"
                      placeholder={t("Phone Number")}
                      className=" mt-4 ps-3 Details_requist_input "
                    />
                    <input
                      type="text"
                      onChange={getTourinfo}
                      name="email"
                      placeholder={t("Email")}
                      className=" mt-4 ps-3 Details_requist_input"
                    />
                    <input
                type="text"
                onChange={getTourinfo}
                name="message"
                placeholder={t("Message")}
                className="Details_requist_mess mt-4 ps-3   "
              />
                  </div>
                  <div className="text-center mt-4">
                    <button type="submit" className="Details_request_btn ">
                      {t("Send ➢")} 
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
      <section>
        <Footer />
      </section>
    </>
  );
}
