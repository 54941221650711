import { Link } from "react-router-dom";
import "./Navbar.css";
import { useState } from "react";
import Logo from "../../photos/gsp company - white-01/white logo-01.png";
import LanguageSelector from "../LanguageSwitcher/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { BsLayoutTextSidebar } from "react-icons/bs";

export default function NavBar() {

  const { t } = useTranslation();
  return (
    <>
      <section className="  m-nav-78 ">
       
          <nav className="navbar navbar-expand-lg py-2  ">
            <div className="container-fluid">
              <Link to={"/"}>
                <img src={Logo} alt="" className="logo_home" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="fs-2 NavHome_btn">
                  <BsLayoutTextSidebar />
                </span>
              </button>
              <div className="collapse navbar-collapse  " id="navbarNav">
              <div
                className=" w-100 NaveHome_media ms-9  "
               
              >
            
                    <Link
                      to={"/"}
                      className=" text-decoration-none  Nave_media  nav_m_inline_start "
                    >
                      {t("Home")}
                    </Link>
                 
                 
                    <Link
                      to={"/AboutUs"}
                      className="Nav_Link text-decoration-none  Nave_media "
                    >
                      {t("About")}
                    </Link>
                    <Link
                      to={"/products"}
                      className="Nav_Link text-decoration-none  Nave_media "
                    >
                      {t("Products")} 
                    </Link>
                    <Link
                      to={"/Services"}
                      className=" Nav_Link text-decoration-none  Nave_media "
                    >
                      {t("Service")}
                    </Link>
                 
                    <Link
                      to={"/ContactUs"}
                      className="Nav_Link text-decoration-none  Nave_media "
                    >
                      {t("Contact us")}
                    </Link>
                    <Link
                     
                      className="Nav_Link text-decoration-none  Nave_media "
                    >
                     <LanguageSelector   />
                    </Link>
                   
                
              </div>
            </div>
            </div>
          </nav>
     
      </section>
    </>
  );
}
