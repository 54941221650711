import "./Footer.css";
import { FiFacebook } from "react-icons/fi";
import { FaInstagram } from "react-icons/fa";

import { BsTwitterX } from "react-icons/bs";
import { BsTelephone } from "react-icons/bs";
import { CiMail } from "react-icons/ci";
import { CiLocationOn } from "react-icons/ci";
import Logo from "../../photos/gsp company - white-01/white logo-01.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Footer() {
  const { t } = useTranslation();
  const generateWhatsAppLink = () => {
    // Replace "1234567890" with your WhatsApp number
    const phoneNumber = "01010749386";
    // Replace "Hello!" with your pre-filled message
    const message = "Welcom to EgyptGSP";
    const countryCode = "+2";
    return `https://wa.me/${countryCode}${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
  };

  return (
    <>
      <section className=" footer_bg h-100 ">
        <div className="m-78">
          <div className="row  container-fluid justify-content-center  ">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-12 text-white mt-5 h4_footer_media">
              <div className="logo_footer">
                <img src={Logo} alt="" className="w-100 h-100" />
              </div>
              <p className="">
                {t(
                  "The company has been able to prove its worth in many businesses"
                )}
                <br />
                {t(
                  "and We always strive to outperform ourselves more and more"
                )}
                <br />
                {t(
                  "and We are keen to provide the best quality products and meet the needs to provide a completely smart home."
                )}
              </p>
                <div className="mt-4">
                <Link to={"https://www.facebook.com/profile.php?id=61555939085643"} className="text-decoration-none"><FiFacebook className="fs-2 me-4 footer_Links" /></Link>
                <Link to={"https://www.instagram.com/prokercompany/"} className="text-decoration-none"> <FaInstagram className="fs-2 me-4 footer_Links" /></Link>
                <Link to={""} className="text-decoration-none"><BsTwitterX className="fs-2 me-4 footer_Links"/></Link>
            
              
              </div>
            </div>
            <div className=" offset-1 col-xxl-2 col-xl-2 col-lg-2 col-6 mt-5 text-white mx-auto h4_footer_media">
              <h2 className="mt-5 ">{t("Links")}</h2>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                className="text-decoration-none"
                to={"/"}>
                <li className=" mt-4 footer_Links">{t("Home")}</li>
              </Link>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                className="text-decoration-none"
                to={"/AboutUs"}>
                {" "}
                <li className=" mt-3 footer_Links">{t("About")}</li>
              </Link>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                className="text-decoration-none"
                to={"/products"}>
                <li className=" mt-3 footer_Links">{t("Products")}</li>
              </Link>
             
              <Link
                onClick={() => window.scrollTo(0, 0)}
                className="text-decoration-none"
                to={"/Services"}>
                <li className=" mt-3 footer_Links">{t("Service")}</li>
              </Link>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                className="text-decoration-none"
                to={"/ContactUs"}>
                {" "}
                <li className=" mt-3 footer_Links">{t("Contact US")}</li>
              </Link>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-6 mt-5 text-white h4_footer_media">
              <h2 className="mt-5">{t("Contact Info")}</h2>
              <Link className="text-decoration-none"><h4 className="mt-5 footer_Links">
                <BsTelephone className="me-3 " />{t("+201010749386")} 
              </h4>
              </Link>
              <Link to={"https://mail.google.com/mail/?view=cm&fs=1&to=gspegypt@gmail.com"} className="text-decoration-none"><h4 className="mt-5  footer_Links">
                <CiMail className="me-3 " />
                {t("gspegypt@gmail.com")}
              </h4>
              </Link>
              {/* <Link to={"https://www.google.com/maps/place/30%C2%B048'09.0%22N+30%C2%B059'46.7%22E/@30.8024897,30.9956515,19z/data=!3m1!4b1!4m4!3m3!8m2!3d30.8024885!4d30.9962952?entry=ttu"} className="text-decoration-none"> <h4 className="mt-5 footer_Links">
                <CiLocationOn className="me-3 " />
                {t("Elbaher street")}
              </h4>
              </Link> */}
            </div>
            <div className="footer_line"> </div>
            <div>
              <h6 className="text-white pt-2 ">
                {t("copyright © 2024 EgyptGSP")}
              </h6>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
