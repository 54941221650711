import "./App.css";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import MainRoutes from "./routes/rouet";
import LanguageWrapper from "./component/LanguageSwitcher/LanguageWrapper";
import { UserContext } from "./UserContext";
import { useState } from "react";

function App() {
  const [user, setuser] = useState(null);
  return (
    <LanguageWrapper>
      <UserContext.Provider value={[user, setuser]}>
        <BrowserRouter>
          <MainRoutes />
        </BrowserRouter>
      </UserContext.Provider>
    </LanguageWrapper>
  );
}

export default App;
