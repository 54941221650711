import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

export const UseAxiosPostContactUs = (formData) => {
  // const secret = process.env.REACT_APP_SECRET_KEY;
  // const storedEncryptedData =
  //   localStorage.getItem("encryptedToken") ?? "nothing";
  // const decryptedData = decrypt(storedEncryptedData, secret);
  // const [data, setData] = useState(null);
  const [response, setResponse] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const HandelPostApi = async () => {
    let request = await axios
      .post(`/api/email/contactUs`, formData, {
        headers: {
         
        },
      })

      .then((response) => {
        setResponse(response);
        toast.success(`✔️ "The Message Send Successfully" `);
      })
      .catch((error) => {
        // check for the response property of the error object
        if (error.response) {
          // set the error message state variable with the error message
          setErrorMessage(error.response.data.message.join(" "));
          toast.error(`❌${error.response.data.message.join(" ")}`);
        }
      });
  };

  return { response, ErrorMessage, HandelPostApi };
};
export const UseAxiosPostDemandandSell = (formData) => {
  // const secret = process.env.REACT_APP_SECRET_KEY;
  // const storedEncryptedData =
  //   localStorage.getItem("encryptedToken") ?? "nothing";
  // const decryptedData = decrypt(storedEncryptedData, secret);
  const [data, setData] = useState(null);
  const [response, setResponse] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const HandelPostApi = async (GroupID) => {
    let request = await axios
      .post(`/contact/sell-demand`, formData, {
        headers: {},
      })

      .then((response) => {
        setResponse(response);
        setData(response);
        toast.success(`✔️ "The Details Send successfully" `);
      })
      .catch((error) => {
        // check for the response property of the error object
        if (error.response) {
          // set the error message state variable with the error message
          setErrorMessage(error.response);
          toast.error(`❌${error.response.data.message.join(" ")}`);
        }
      });
  };

  return { response, data, ErrorMessage, HandelPostApi };
};
export const UseAxiosPostitemQuestion = (formData) => {
  // const secret = process.env.REACT_APP_SECRET_KEY;
  // const storedEncryptedData =
  //   localStorage.getItem("encryptedToken") ?? "nothing";
  // const decryptedData = decrypt(storedEncryptedData, secret);
  const [data, setData] = useState(null);
  const [response, setResponse] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const HandelPostApi = async () => {
    let request = await axios
      .post(`/api/email/itemQuestion`, formData, {
        headers: {},
      })

      .then((response) => {
        setResponse(response.message);
        setData(response);
        toast.success(`✔️ "The Tour requist has been send successfully" `);
      })
      .catch((error) => {
        // check for the response property of the error object
        if (error.response) {
          // set the error message state variable with the error message
          setErrorMessage(error.response);
          toast.error(`❌`);
        }
      });
  };

  return { response, data, ErrorMessage, HandelPostApi };
};

export const UseAxiosPostPeriod = (formData) => {
  // const secret = process.env.REACT_APP_SECRET_KEY;
  // const storedEncryptedData =
  //   localStorage.getItem("encryptedToken") ?? "nothing";
  // const decryptedData = decrypt(storedEncryptedData, secret);
  const [data, setData] = useState(null);
  const [response, setResponse] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const HandelPostApi = async (GroupID) => {
    let request = await axios
      .post(`/api/v1/period?group_id=${GroupID}`, formData, {
        headers: {},
      })

      .then((response) => {
        setResponse(response);
        setData(response);
        toast.success(`✔️ "The Period has been Added successfully" `);
      })
      .catch((error) => {
        // check for the response property of the error object
        if (error.response) {
          // set the error message state variable with the error message
          setErrorMessage(error.response);
          toast.error(`❌`);
        }
      });
  };

  return { response, data, ErrorMessage, HandelPostApi };
};
export const UseAxiosProfile = (formData) => {
  // const secret = process.env.REACT_APP_SECRET_KEY;
  // const storedEncryptedData =
  //   localStorage.getItem("encryptedToken") ?? "nothing";
  // const decryptedData = decrypt(storedEncryptedData, secret);
  const [data, setData] = useState(null);
  const [response, setResponse] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const HandelPostApi = async (StudentID) => {
    let request = await axios
      .post(`/api/v1/notice?student_id=${StudentID}`, formData, {})

      .then((response) => {
        setResponse(response);
        setData(response);
        toast.success(`✔️ "The Notice has been Added successfully" `);
      })
      .catch((error) => {
        // check for the response property of the error object
        if (error.response) {
          // set the error message state variable with the error message
          setErrorMessage(error.response);
          toast.error(`❌`);
        }
      });
  };

  return { response, data, ErrorMessage, HandelPostApi };
};
