import { Route, Routes } from "react-router-dom";
import Home from "../pages/home/Home";
import axios from "axios";
import AboutUs from "../pages/AboutUS/AboutUs";
import Services from "../pages/Services/Services";

import ContactUs from "../pages/ContactUs/ContactUs";


import Details from "../pages/Details/Details";
import Products from "../pages/Products/Products";

function MainRoutes() {
  axios.defaults.baseURL = "https://backend.egyptgsp.com/";

  return (
    <>
      <Routes>
        <Route path="/" exact element={<Home />} />  
        
        <Route path="/products" exact element={<Products />} />
        <Route path="/AboutUs" exact element={<AboutUs />} />
        <Route path="/Services" exact element={<Services />} />
        <Route path="/Details/:ID" exact element={<Details />} />
        <Route path="/ContactUs" exact element={<ContactUs />} />
      </Routes>
    </>
  );
}

export default MainRoutes;
